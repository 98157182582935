import { useEffect, useRef } from 'react';
import type { UseFormResetField, UseFormWatch } from 'react-hook-form';

import type { BasicExpression, Condition, PreEvaluatedExpression, Question } from '../../client';
import type { FormValues } from '../../survey';
import type { Content } from '../../types';
import {
  isMultipleSelect,
  isQuestion,
  isSection,
  isSingleSelect,
} from '../../utils/type-narrowing';

export function useConditionalQuestions({
  contentItems,
  watch,
  resetField,
}: {
  contentItems: Content[];
  watch: UseFormWatch<FormValues>;
  resetField: UseFormResetField<FormValues>;
}) {
  const watched = watch();
  const fieldsToResetRef = useRef<Set<string>>(new Set());

  //This useEffect is used to reset the fields values that are not visible anymore especially triggers.
  useEffect(() => {
    fieldsToResetRef.current.forEach((fieldId) => {
      resetField(fieldId);
    });
    fieldsToResetRef.current.clear();
  });

  function hasTriggers(question: Question) {
    return Boolean(question.triggers?.length);
  }

  function hasCondition(question: Question) {
    return Boolean(question.condition);
  }

  function getBooleanResult(condition: Condition, watched: FormValues): boolean {
    // TODO: Implement the logic for 'and' and 'or'. Right now, it only supports 'and'. Waiting for the API to expose some example with `or`.
    const cond = condition.cond;

    // TODO: Get all possible multiple expressions. Waiting for the API to expose some examples with more than one expression per condition.
    const expression = condition.expressions[0];

    if (!expression) {
      return false;
    }

    if ('result' in expression) {
      return (expression as PreEvaluatedExpression).result;
    }

    if ('id' in expression) {
      const parentQuestionId = (expression as BasicExpression).id;
      const watchedValue = watched[parentQuestionId];
      if (watchedValue === null) {
        return false;
      }
      const triggerValues = (expression as BasicExpression).values;
      if (isSingleSelect(watchedValue)) {
        if ((expression as BasicExpression).operator === 'in') {
          return triggerValues.includes(watchedValue);
        }
        if ((expression as BasicExpression).operator === 'not_in') {
          return !triggerValues.includes(watchedValue);
        }
      }
      if (isMultipleSelect(watchedValue as unknown as unknown[])) {
        if ((expression as BasicExpression).operator === 'in') {
          return expression.values.some((v) =>
            (watchedValue as (number | string | boolean | null)[]).includes(v)
          );
        }
        if ((expression as BasicExpression).operator === 'not_in') {
          return !expression.values.some((v) =>
            (watchedValue as (number | string | boolean | null)[]).includes(v)
          );
        }
      }
    }

    if ('cond' in expression) {
      // Recursively process its nested conditions
      (expression.expressions as Condition[]).map((condition) =>
        getBooleanResult(condition, watched)
      );
    }

    return false;
  }

  // Previous implementation using the old `triggers` property.
  function hasActiveTriggers(question: Question) {
    const triggers = question.triggers;

    // A conditional question can have multiple triggers.
    const parentQuestionIds = triggers?.map((t) => t.question_id) ?? [];

    const watchedParentQuestions = parentQuestionIds.reduce<
      Record<string, string | number | boolean | string[] | number[]>
    >((obj, id) => {
      const watchedValue = watched[id];
      if (watchedValue !== null) {
        return { ...obj, [id]: watchedValue };
      }
      return obj;
    }, {});

    for (const id in watchedParentQuestions) {
      const watchedValue = watchedParentQuestions[id];
      const triggerValues = triggers?.find((t) => t.question_id === id)?.values ?? [];
      if (isSingleSelect(watchedValue)) {
        return triggerValues.includes(watchedValue);
      }
      if (isMultipleSelect(watchedValue)) {
        // @ts-expect-error Type 'string' is not assignable to type 'never'.
        return triggerValues.some((v) => watchedValue.includes(v));
      }
    }

    return false;
  }

  function hasConditionMet(question: Question) {
    if (!question.condition) {
      return false;
    }

    return getBooleanResult(question.condition, watched);
  }

  function shouldRender(content: Content) {
    // Render anything that is not a question or a section.
    if (!isQuestion(content) && !isSection(content)) {
      return true;
    }

    // Render the section title only when it has, at least, one visible content item.
    if (isSection(content)) {
      const section = content;
      const sectionIndex = contentItems.findIndex((item) => item.id === section.id);
      for (let i = sectionIndex + 1; i < contentItems.length && !isSection(contentItems[i]); i++) {
        if (shouldRender(contentItems[i])) {
          return true;
        }
      }
      return false;
    }

    // Render all questions without conditions and conditional questions only when their conditions are met.
    const question = content;
    const shouldRenderQuestion =
      // TODO: Stop using the old implementation (triggers)
      !hasTriggers(question) ||
      hasActiveTriggers(question) ||
      // TODO: Keep using the new implementation (condition)
      !hasCondition(question) ||
      hasConditionMet(question);

    // Hack to avoid unnecessary rerenders when showing/hiding conditional questions.
    if (!shouldRenderQuestion) {
      fieldsToResetRef.current.add(question.id);
    }

    return shouldRenderQuestion;
  }

  return { shouldRender };
}
